import styled from "styled-components";
import {
	MOBILE_L_BP,
	FONT_WT_HEAVY,
	COLOR_WHITE,
	FONT_WT_LIGHT,
	COLOR_BORDER_L_GREY,
} from "@website-builder/utilities-index";

export const ProgramWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: solid 1px ${COLOR_BORDER_L_GREY};
	background-color: ${COLOR_WHITE};
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		flex-direction: column;
		margin: 0;
	}
	.group {
		display: flex;
		flex-direction: row;
	}
`;

export const HeaderWrapper = styled.div`
	background-color: var(--secondary);
`;

export const ContentWrapper = styled.div`
	width: 280px;
	margin: 10px 0;
	background-color: ${COLOR_WHITE};
`;

export const ProgramItemWrapper = styled.ul`
	list-style: square;
`;

export const ProgramItem = styled.li`
	margin-top: 10px;
	font-size: 18px;
	line-height: 23px;
`;

export const CohortSchedule = styled.div`
	margin: 5px 0;
	display: inline-flex;
	font-weight: ${FONT_WT_HEAVY};
`;

export const CohortDate = styled.div`
	font-weight: ${FONT_WT_LIGHT};
	margin-left: 4px;
`;

export const Header = styled.h2`
	font-weight: ${FONT_WT_HEAVY};
	font-size: 22px;
	line-height: 28px;
	text-align: center;
	margin: 15px 10px;
	max-width: 278px;
	word-wrap: break-word;
	color: ${COLOR_WHITE};
`;

export const ProgramInfoToBeRendered = styled.div`
	height: 80px;
`;
