import React from "react";
import { getStoriesByContentType } from "@website-builder/services";
import { getProgramListInfo } from "@website-builder/services";
import {
	ProgramWrapper,
	Header,
	HeaderWrapper,
	ContentWrapper,
	ProgramItem,
	CohortSchedule,
	CohortDate,
	ProgramItemWrapper,
	ProgramInfoToBeRendered,
} from "./styles";
import { useQuery, useQueries } from "react-query";

const UpcomingPrograms = ({ header, context, programList = [] }) => {
	const contentType = "Program";
	const { token } = context;
	const { data: programListPreview = [] } = useQuery(
		"storiesList",
		() => getStoriesByContentType(contentType, token && token.private),
		{
			// This api will run only in Preview Instance. In static we receive programList in props.
			enabled: programList.length === 0,
		},
	);
	if (programListPreview.length) {
		programList = programListPreview;
	}
	const results = useQueries(getProgramListInfo(programList));
	let programs = results.filter(
		(item) => item.isFetched === true && item.status === "success",
	);
	if (programs.length >= programList.length) {
		programs = results.map((item) => {
			return item.data;
		});
	}

	const renderPrograms = () => {
		if (programs.length) {
			return programs.map((item, index) => {
				const { content = {}, name = "" } = item;
				const { next_date_string = "" } = content;
				return (
					<ProgramItemWrapper key={`name-${index}`}>
						<ProgramItem>{name}</ProgramItem>
						{next_date_string && (
							<CohortSchedule>
								Upcoming cohort: <CohortDate>{next_date_string}</CohortDate>
							</CohortSchedule>
						)}
					</ProgramItemWrapper>
				);
			});
		} else return <ProgramInfoToBeRendered></ProgramInfoToBeRendered>;
	};
	if (programs.length || programList.length)
		return (
			<ProgramWrapper>
				<HeaderWrapper>
					<Header>{header}</Header>
				</HeaderWrapper>
				<ContentWrapper>{renderPrograms()}</ContentWrapper>
			</ProgramWrapper>
		);
	else return null;
};

export default UpcomingPrograms;
